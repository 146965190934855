<template>
  <div class="shanhai">
    <img :src="pc_pic" alt="">
    <div class="text-center">正在开发中...</div>
  </div>
</template>
<script>
import router from '@/router/index';
export default {
  name: "shanhai",
  data () {
    return {
      pc_pic: require("@/assets/images/ai1.png"),
    };
  },
  mounted () {
  },
  methods: {
  },
};
</script>
<style lang="less">
.shanhai {
  height: 50%;
  color: red;

  .text-center {
    font-size: 0.5rem;
  }

  img {
    height: 100%;
  }
}

@media screen and (max-width: 767px) {
  .shanhai {
    img {
      margin-top: 0.3125rem;
      width: 100% ;
      height: auto ;
    }
  }
}
</style>